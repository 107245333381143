import React, { useEffect, useState } from 'react';
import { Tabs, Tab } from "@material-ui/core";
import Layout from "../../components/shared-layout";
import TabPanel from '../../components/tab-panel';
import useStores from "../../hooks/use-stores";
import { observer } from 'mobx-react';

import FeedbackAnalysis from '../../components/school-executives/feedback-analysis';
import SubmissionAnalysis from '../../components/school-executives/submission-analysis';
import CohortAnalysis from '../../components/school-executives/cohort-analysis';
import OrderOfStudents from '../../components/school-executives/order-of-students';
import { useTranslation } from 'react-i18next';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { getPermissionValidity, PermissionCategory } from '../../constants/options';
import TimelineIcon from '@material-ui/icons/Timeline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import SortByAlphaIcon from '@material-ui/icons/SortByAlpha';

function a11y1Props(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `${isActive ? 'font-bold text-barPrimary' : 'text-black'} `
  };
}

function a11y2Props(index, isActive) {
  return {
    id: `simple-tab-2-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const AcademicsPage = observer(() => {
  const { t } = useTranslation();
  const { academicStore, filterStore } = useStores();
  const childTabStyle = "rounded-b-xl shadow-lg border mx-2";
  const [value, setValue] = useState<number>(0);
  const [innerValue, setInnerValue] = useState<number>(0);
  const [showSlideDown, setShowSlideDown] = useState<boolean>(false);
  const [isArrivedPage, setIsArrivedPage] = useState<boolean>(false);

  const handleOuterChange = (event, newValue) => {
    setValue(newValue);
    setInnerValue(0);
  };

  const handleInnerChange = (event, newValue) => {
    setInnerValue(newValue);
  };

  useEffect(() => {
    // filterStore.getSemesterDateList().then(() => {
    // filterStore.getCurrentSemester();
    if (filterStore.academicYearList.length > 0 && filterStore.domainList.length > 0 &&
      filterStore.filteredHomeRoomCodeList.length > 0 && !isArrivedPage) {
      let yearDescription = "";
      let semesterDescription = "";
      if (filterStore.currentSemester == 0) filterStore.getCurrentSemester();
      let lastSemester = filterStore.currentSemester;
      for (var year of filterStore.academicYearList) {
        if (year.academicSemesters.find(s => s.id == lastSemester)) {
          yearDescription = year.yearDescription;
          semesterDescription = year.academicSemesters.find(s => s.id == lastSemester).semesterDescription;
          break;
        }
      }
      filterStore.selectedSemester = semesterDescription;

      var reqData: any = {
        semesterIdList: [lastSemester],
        homeRoomList: filterStore.filteredHomeRoomCodeList.map((hr: any) => hr.homeroomCode),
        subjectClassIdList: [],  // All subjects
        domainDescriptionList: filterStore.domainList.map((d: any) => d.domainDescription),

      }
      console.log(reqData);
      if (getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskSubmission")) {
        academicStore.getFeedbackAnalysisData(reqData);
        academicStore.getSubmissionAnalysisData(reqData);
      }
      if (getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskPerformance")) {
        academicStore.getPerformanceAnalysisData(reqData);
        academicStore.getAveragePercentageByStudent(reqData);
        academicStore.getStudentPerformanceAndGrowthRateDistribution(reqData);
        academicStore.getStudentSubmissionStatusChart(reqData);
      }
      filterStore.selectedYear = new Date().getFullYear().toString();
      filterStore.selectedHomeroom = t('ALL');
      filterStore.selectedDomain = t('ALL');
      filterStore.selectedSubject = t('ALL');
      filterStore.selectedClass = t('ALL');
      setIsArrivedPage(true);
    }
    // })
  }, [filterStore.currentSemester, filterStore.homeRoomCodeList, filterStore.domainList]);

  const onApplyFilter = (req) => {
    var tempSubjectClassIdList = []
    if (req.subject !== 0) {
      if (req.subjectClass !== 0) {
        tempSubjectClassIdList.push(req.subjectClass);
      }
      else {
        filterStore.subjectClassList.map(e => {
          tempSubjectClassIdList.push(e.id);
        });
      }
    }

    var reqData = {
      semesterIdList: req.semesterList,
      homeRoomList: req.classCodeFullList,
      domainDescriptionList: req.domainDescList,
      subjectClassIdList: tempSubjectClassIdList,
    }
    console.log(reqData);
    if (getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskSubmission")) {
      academicStore.getFeedbackAnalysisData(reqData);
      academicStore.getSubmissionAnalysisData(reqData);
    }

    if (getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskPerformance")) {
      academicStore.getPerformanceAnalysisData(reqData);
      academicStore.getAveragePercentageByStudent(reqData);
      academicStore.getStudentPerformanceAndGrowthRateDistribution(reqData);
      academicStore.getStudentSubmissionStatusChart(reqData);
    }
  }

  return (
    <>
      {
        typeof window !== "undefined" &&
        <Layout
          showSeachStudent={true}
          showYearSemTerm={true}
          showClassCode={true}
          showDomain={true}
          showSubjectAndSubjectClass={true}
          showStaffList={false}
          onApplyFilter={onApplyFilter}
        >
          <title>{t('SCHOOL_EXECUTIVES')}</title>
          <div className="col-span-4">
            <div className="mx-2 mt-2 py-2 rounded-t-xl border border-b-0">
              <Tabs value={value}
                onChange={handleOuterChange}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  },
                }} >
                {getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskSubmission") &&
                  < Tab label={t('TASK_SUBMISSION_ANALYSIS')} {...a11y1Props(0, 0 === value)}
                    icon={<AssessmentIcon />} />
                }
                {getPermissionValidity(PermissionCategory.SCHOOL, "SchoolExecutive", "TaskPerformance") &&
                  <Tab label={t('TASK_PERFORMANCE_ANALYSIS')} {...a11y1Props(1, 1 === value)}
                    icon={<TimelineIcon />} />
                }
              </Tabs>
            </div>

            <TabPanel value={value} paddingValue={0} index={0}>
              {/* Task Submission Analysis */}
              <div className={childTabStyle}>
                <Tabs value={innerValue}
                  onChange={handleInnerChange}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }} >
                  <Tab icon={<ThumbUpIcon />} label={t('FEEDBACK_ANALYSIS')} {...a11y2Props(0, 0 === innerValue)} />
                  <Tab icon={<PlaylistAddCheckIcon />} label={t('SUBMISSION_ANALYSIS')} {...a11y2Props(1, 1 === innerValue)} />
                </Tabs>
              </div>

              <TabPanel value={innerValue} paddingValue={0} index={0}>
                {/* Feedback Analysis */}

                <FeedbackAnalysis
                  callback={() => setShowSlideDown(!showSlideDown)}
                  showSlideDown={showSlideDown}
                />
              </TabPanel>
              <TabPanel value={innerValue} paddingValue={0} index={1}>
                {/* Submission Analysis */}

                <SubmissionAnalysis
                  callback={() => setShowSlideDown(!showSlideDown)}
                  showSlideDown={showSlideDown}
                />
              </TabPanel>
            </TabPanel>

            <TabPanel value={value} paddingValue={0} index={1}>
              <div className={childTabStyle}>
                <Tabs value={innerValue}
                  onChange={handleInnerChange}
                  TabIndicatorProps={{
                    style: {
                      display: "none",
                    },
                  }} >
                  <Tab icon={<FormatListNumberedIcon />} label={t('COHORT_ANALYSIS')} {...a11y2Props(0, 0 === innerValue)} />
                  <Tab icon={<SortByAlphaIcon />} label={t('ORDER_STUDENTS_IN_YEAR_LEVEL')} {...a11y2Props(1, 1 === innerValue)} />
                </Tabs>
              </div>

              <TabPanel value={innerValue} paddingValue={0} index={0}>

                <CohortAnalysis
                  callback={() => setShowSlideDown(!showSlideDown)}
                  showSlideDown={showSlideDown}
                />
              </TabPanel>
              <TabPanel value={innerValue} paddingValue={0} index={1}>

                <OrderOfStudents
                  callback={() => setShowSlideDown(!showSlideDown)}
                  showSlideDown={showSlideDown}
                />
              </TabPanel>
            </TabPanel>

          </div>
        </Layout>
      }
    </>
  )
})

export default AcademicsPage;