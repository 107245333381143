import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { BAR_RADIUS } from '../../constants/style';

const IESStackedBarChart = observer(({ setting, onEvents = null }) => {
  var option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: setting.xAxis,
      axisLabel: {
        margin: 6,
        fontSize: 10,
        rotate: setting.rotate ? setting.rotate : 50
      }
    },
    yAxis: {
      type: 'value'
    },
    series: setting.series,

  }

  const addBarBorderRadius = (option) => {
    let target = option.series;
    if (target.length > 0) {
      for (var i = 0; i < target[3].data.length; i++) {
        if (target[3].data[i].value) {
          if (target[2].data[i].value) {
            if (target[1].data[i].value) {
              target[0].data[i] = {
                value: target[0].data[i],
                // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
              };
            } else {
              target[1].data[i] = {
                value: target[1].data[i],
                // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
              };
            }
          } else {
            target[2].data[i] = {
              value: target[2].data[i],
              // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
            };
          }
        } else {
          console.log("barr", target[3].data[i]);
          target[3].data[i] = {
            value: target[3].data[i],
            // itemStyle: { barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0] }
          };
        }
      }
    }
  }


  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          margin: '0 auto',
        }}
        option={option}
        onEvents={onEvents}
      />
    </>
  );
});

export default IESStackedBarChart;