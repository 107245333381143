import React, { useState, useEffect } from "react";
import useStores from "../../hooks/use-stores";
import { CircularProgress, Tab, Tabs, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import IESBoxPlot from "../charts/iesBoxplot";
import IESFrequencyPlot from "../charts/iesFrequencyPlot";
import { CHART_WIDTH, getCurrentThemeColour, TAG_STYLE } from "../../constants/style";
import TabPanel from "../tab-panel";
import { toJS } from "mobx";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SlideDown from "react-slidedown";
import { useTranslation } from 'react-i18next';
import VMCard from "../collapsed-card";
import IESClusteringDotGraph from "../charts/iesClusteringDotGraph";
import FilterDisplay from "./se-filter-display";
import TabDivider from "../tab-divider";


function tabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const CohortAnalysis = observer(({ callback, showSlideDown }) => {
  const { academicStore, filterStore } = useStores();
  const headingStyle = "text-barPrimary p-4 text-lg 2xl:text-2xl font-bold";
  const [studentPerformanceDensity, setStudentPerformanceDensity] = useState([]);
  const [studentMarksClassCode, setStudentMarksClassCode] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 17, 13, 31],
      [23, 34, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 23, 34, 45, 56, 67, 78, 10, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 62, 56],
    ],
    category: ['C1', 'C2', 'C3', 'C4']
  })
  const [studentMarksYear, setStudentMarksYear] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 17, 13, 31],
      [23, 34, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 23, 34, 45, 56, 67, 78, 10, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 62, 56],
    ],
    category: ['2018', '2019', '2020', '2021']
  })
  const [studentMarksDomain, setStudentMarksDomain] = useState({
    boxData: [
      [23, 45, 34, 54, 32, 43, 52, 28, 54, 15, 77, 73, 31],
      [23, 24, 45, 56, 67, 78, 23, 34, 53, 23, 54, 62, 56],
      [23, 34, 45, 56, 33, 34, 45, 56, 67, 78, 80, 67, 78,],
      [23, 34, 45, 56, 67, 78, 23, 24, 53, 73, 54, 42, 96],
      [23, 24, 45, 56, 67, 78, 23, 34, 53, 23, 54, 67, 56],
    ],
    category: ['C1', 'C2', 'C3', 'C4', 'Y7']
  })
  const [sPerformanceGrowth, setSPerformanceGrowth] = useState<any>([]);
  const [sGrowthAverage, setSGrowthAverage] = useState<number>(0);
  const [prevPerformance, setPrevPerformance] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const { t } = useTranslation();

  useEffect(() => {
    if (academicStore.performanceAnalysisData) {
      if (academicStore.performanceAnalysisData.performanceOverView) {
        setStudentPerformanceDensity(academicStore.performanceAnalysisData.performanceOverView.doubleList);
        console.log("Box plot data", toJS(academicStore.performanceAnalysisData.performanceOverView.doubleList));
      }
      if (academicStore.performanceAnalysisData.marksByClassCode) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByClassCode.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })
        setStudentMarksClassCode({
          boxData: tempboxData,
          category: tempCategory
        });
      }
      if (academicStore.performanceAnalysisData.marksByYearLevel) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByYearLevel.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })
        setStudentMarksYear({
          boxData: tempboxData,
          category: tempCategory
        });
      }
      if (academicStore.performanceAnalysisData.marksByDomain) {
        let tempboxData = [];
        let tempCategory = [];
        academicStore.performanceAnalysisData.marksByDomain.map((d) => {
          tempCategory.push(d.category)
          tempboxData.push(d.doubleList)
        })

        setStudentMarksDomain({
          boxData: tempboxData,
          category: tempCategory
        });
      }
    }
  }, [academicStore.performanceAnalysisData]);

  useEffect(() => {
    if (academicStore.sPerformanceGrowthData.length > 0) {
      let avgMark = academicStore.sPerformanceGrowthData.map(data => +data.performance)
        .reduce((partialSum, a) => partialSum + a, 0) / academicStore.sPerformanceGrowthData.length;
      setSGrowthAverage(avgMark);
      setPrevPerformance(academicStore.sPerformanceGrowthData.map(data => ({ name: data.name, previousPerformance: data.previousPerformance })));
      let tempGrowthData: any[] = [];
      for (var growth of academicStore.sPerformanceGrowthData) {
        tempGrowthData.push({
          name: growth.name,
          value: [growth.growthRate, parseFloat((+growth.performance - avgMark).toFixed(2))],
          itemStyle: {
            color: growth.growthRate > 0 && parseFloat((+growth.performance - avgMark).toFixed(2)) > 0 ? "#129155"
              : growth.growthRate > 0 && parseFloat((+growth.performance - avgMark).toFixed(2)) < 0 ? "#dce923"
                : growth.growthRate < 0 && parseFloat((+growth.performance - avgMark).toFixed(2)) > 0 ? "#e98d23"
                  : growth.growthRate < 0 && parseFloat((+growth.performance - avgMark).toFixed(2)) < 0 ? "#d10956"
                    : parseFloat((+growth.performance - avgMark).toFixed(2)) > 0 ? "#129155" : "#dce923"
          }
        })
      }
      console.log("distrbution", avgMark, toJS(tempGrowthData));

      setSPerformanceGrowth(tempGrowthData);
    }
  }, [academicStore.sPerformanceGrowthData])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="m-4 mx-2">

      <div className="rounded-t-xl border border-b-0 p-4 text-barPrimary text-xl font-bold mt-4 grid grid-cols-2">
        <div className="py-2 uppercase">{t('COHORT_ANALYSIS')}</div>
      </div>

      <Tabs
        className="border rounded-b-xl shadow-lg mb-2"
        TabIndicatorProps={{ style: { display: "none" } }}
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <Tab value={0} label={t('PERFORMANCE_OVERVIEW')} {...tabProps(0, 0 === tabValue)} />
        <TabDivider />
        <Tab value={1} label={t('MARKS_ACROSS_DOMAIN')} {...tabProps(1, 1 === tabValue)} />
        <TabDivider />
        <Tab value={2} label={t('CORHORT_GROWTH')} {...tabProps(2, 2 === tabValue)} />
      </Tabs>

      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('STUDENT_PERFORMANCE_OVERVIEW_BY_DENSITY')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                studentPerformanceDensity.length > 0 ?
                  <IESFrequencyPlot
                    dataAndSetting={{
                      data: studentPerformanceDensity,
                      height: 450,
                      width: CHART_WIDTH,
                      barColor: getCurrentThemeColour()
                    }}
                  />
                  : <div className="text-barPrimary text-center">
                    <Typography>{t('NO_RESULT')}</Typography>
                  </div>
            }
          </div>
        </VMCard>

      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={1}>
        <VMCard>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            <div className="text-barPrimary text-center my-4">{t('STUDENT_MARKS_ACROSS_DOMAINS_BY_CLASS_CODE')}</div>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBoxPlot
                  dataAndSetting={{
                    data: studentMarksClassCode,
                    height: '350px',
                    width: '100%',
                    xAxisLabelRotate: 90,
                    showDataZoom: false,
                  }}
                />
            }
            <div className="my-4 grid grid-cols-2">
              <div>
                <div className="text-center text-barPrimary my-4">
                  {t('STUDENT_MARKS_ACROSS_DOMAINS_BY_YEAR_LEVEL')}
                </div>
                {
                  academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <IESBoxPlot
                      dataAndSetting={{
                        data: studentMarksYear,
                        height: '350px',
                        width: '400px',
                        xAxisLabelRotate: 0,
                        showDataZoom: false,
                      }}
                    />
                }
              </div>
              <div>
                <div className="text-center text-barPrimary my-4">
                  {t('STUDENT_MARKS_ACROSS_DOMAINS_BY_DOMAIN')}
                </div>
                {
                  academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                    :
                    <IESBoxPlot
                      dataAndSetting={{
                        data: studentMarksDomain,
                        height: '350px',
                        width: '400px',
                        xAxisLabelRotate: 90,
                        showDataZoom: false,
                      }}
                    />
                }
              </div>
            </div>
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={2}>
        <VMCard
          titleClassName="m-4 text-lg font-bold"
          titleComponent={
            <div className="text-barPrimary">{t('COHORT_GROWTH_VS_PERFORMANCE')}</div>
          }
        >
          <FilterDisplay />
          <div className="flex justify-between my-2 mx-4 text-gray-500">
            <div>Low Achievement/High Growth</div>
            <div>High Achievement/High Growth</div>
          </div>
          {
            academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              : <IESClusteringDotGraph
                setting={{
                  data: sPerformanceGrowth,
                  height: 400,
                  width: '100%',
                  averageScore: sGrowthAverage,
                  prevPerformance,
                }}
              />
          }

          <div className="flex justify-between m-4 mt-2 text-gray-500">
            <div>{t('LOW_ACHIEVEMENT_LOW_GROWTH')}</div>
            <div>{t('HIGH_ACHIEVEMENT_LOW_GROWTH')}</div>
          </div>
        </VMCard>
      </TabPanel>
    </div>
  )
})

export default CohortAnalysis;