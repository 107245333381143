import { Button, Checkbox, CircularProgress, Tab, Tabs, Typography } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { CHART_WIDTH, TAG_STYLE, THEME_CLASS } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import { WrittenFeedbackType } from "../../stores/academic-store";
import AdvanceSearchModal from "../advance-search-modal";
import IESBar from "../charts/iesBar";
import IESStackedBar from "../charts/iesStackedBar";
import IESStackedVBar from "../charts/iesStackedVBar";
import StudentTasksAdvancedSearch from "../student-tasks/student-tasks-advanced";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TabPanel from "../tab-panel";
import SlideDown from "react-slidedown";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';
import VMCard from "../collapsed-card";
import FilterDisplay from "./se-filter-display";
import { AnyNode } from "postcss";
import TabDivider from "../tab-divider";

const CURRENT_CHART_HEIGHT = "400px";

function feedbackTabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const FeedbackAnalysis = observer(({ callback, showSlideDown }) => {
  const headingStyle = "text-barPrimary p-4 text-lg 2xl:text-2xl font-bold";
  const { academicStore, filterStore } = useStores();
  const { t } = useTranslation();
  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false);
  const [byMarkingDaysLabel, setByMarkingDaysLabel] = useState<any>([]);
  const [byMarkingDaysAmount, setByMarkingDaysAmount] = useState<any>([]);
  const [byDueDateMarkingDateLabel, setByDueDateMarkingDateLabel] = useState<any>([]);
  const [byDueDateMarkingDateAmount, setByDueDateMarkingDateAmount] = useState<any>([]);
  const [perStudentByClassLabel, setPerStudentByClassLabel] = useState<any>([]);
  const [perStudentByClassAmount, setPerStudentByClassAmount] = useState<any>([]);
  const [nonSubmissionByTeacherLabel, setNonSubmissionByTeacherLabel] = useState<any>([]);
  const [nonSubmissionByTeacherAmount, setNonSubmissionByTeacherAmount] = useState<any>([]);
  const [writtenFeedbackCat, setWrittenFeedbackCat] = useState<any>([
    WrittenFeedbackType.marking, WrittenFeedbackType.file, WrittenFeedbackType.rubric
  ]);
  const [writtenFeedbackLabel, setWrittenFeedbackLabel] = useState<any>([]);
  const [writtenFeedbackAmount, setWrittenFeedbackAmount] = useState<any>([]);
  const [uidCount, setUidCount] = useState<number>(0);
  const [checkedTeacherList, setCheckedTeacherList] = useState<any>([]);
  const [feedbackTypeCount, setFeedbackTypeCount] = useState<number>(0);
  const [activeFunction, setActiveFunction] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [showAllTeachers, setShowAllTeachers] = useState<boolean>(true);
  const [allTeacherList, setAllTeacherList] = useState<any>([]);

  useEffect(() => {
    if (academicStore.feedbackByMarkingDaysList.length > 0) {
      var tempLabel: any[] = [];
      var tempAmount: any[] = [];
      academicStore.feedbackByMarkingDaysList.map((feedback: any, index: number) => {
        tempLabel.push(feedback.label);
        tempAmount.push({
          value: +feedback.amountString,
          itemStyle: {
            color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
          }
        });
      });
      setByMarkingDaysLabel(tempLabel);
      setByMarkingDaysAmount(tempAmount);
    }
  }, [academicStore.feedbackByMarkingDaysList]);

  useEffect(() => {
    if (academicStore.feedbackByDueDateMarkingDateList.length > 0) {
      var tempLabel: any[] = [];
      var tempAmount: any[] = [];
      academicStore.feedbackByDueDateMarkingDateList.map((feedback: any, index: number) => {
        tempLabel.push(feedback.label);
        tempAmount.push({
          value: +feedback.amountString,
          itemStyle: {
            color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
          }
        });
      });
      setByDueDateMarkingDateLabel(tempLabel);
      setByDueDateMarkingDateAmount(tempAmount);
    }
  }, [academicStore.feedbackByDueDateMarkingDateList]);

  useEffect(() => {
    if (academicStore.feedbackPerStudentByClass.length > 0) {
      var tempLabel: any[] = [];
      var tempAmount: any[] = [];
      academicStore.feedbackPerStudentByClass.map((feedback: AnyNode, index: number) => {
        tempLabel.push(feedback.label);
        tempAmount.push({
          value: +feedback.amountString,
          itemStyle: {
            color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
          }
        });
      });
      setPerStudentByClassLabel(tempLabel);
      setPerStudentByClassAmount(tempAmount);
    }
  }, [academicStore.feedbackPerStudentByClass]);

  useEffect(() => {
    if (academicStore.feedbackTableList.length > 0) {
      var countUid = 0;
      var countFeedbackType = 0;
      academicStore.feedbackTableList.map((feedback) => {
        countUid += feedback.countOfUid;
        countFeedbackType += feedback.anyFeedbackType;
      });

      setUidCount(countUid);
      setFeedbackTypeCount(countFeedbackType);
    }
  }, [academicStore.feedbackTableList]);

  useEffect(() => {
    if (academicStore.nonSubmissionByTeacherList.length > 0) {
      var tempLabel: any[] = [];
      var tempAmount: any[] = [];
      academicStore.nonSubmissionByTeacherList.map((feedback: any, index: number) => {
        tempLabel.push(feedback.label);
        tempAmount.push({
          value: feedback.amount,
          itemStyle: {
            color: THEME_CLASS[0].chartColorLibrary[index % THEME_CLASS[0].chartColorLibrary.length]
          }
        });
      });

      setNonSubmissionByTeacherLabel(tempLabel);
      setNonSubmissionByTeacherAmount(tempAmount);
    }
  }, [academicStore.nonSubmissionByTeacherList]);

  useEffect(() => {
    if (academicStore.writtenFeedbackList.length > 0) {
      var tempLabel = [];
      var tempMarkingValue = [];
      var tempFileValue = [];
      var tempRubricValue = [];
      academicStore.writtenFeedbackList.map((feedback) => {
        tempLabel.push(feedback.category);
        feedback.detailData.map((data) => {
          if (data.label == WrittenFeedbackType.marking)
            tempMarkingValue.push(data.amount);
          else if (data.label == WrittenFeedbackType.file)
            tempFileValue.push(data.amount);
          else if (data.label == WrittenFeedbackType.rubric)
            tempRubricValue.push(data.amount);
        })
      });

      setWrittenFeedbackLabel(tempLabel);
      setWrittenFeedbackAmount([tempMarkingValue, tempFileValue, tempRubricValue]);
    }
  }, [academicStore.writtenFeedbackList]);

  const handleTeacherChecks = (event: React.ChangeEvent<{ value: unknown }>) => {
    let value = event.target.value as string;
    let temp = checkedTeacherList;

    // If the all is check now, then it should untick all other checkbox 
    if (value == "All" && !temp.includes("All")) {
      temp = ["All"];
    } else {
      // If other checkboxs are ticked, the all checkbox should be unticked
      if (temp.includes(value)) {
        let index = temp.indexOf(value);
        console.log(temp);

        temp.splice(index, 1);
      } else {
        if (value !== "All" && temp.includes("All")) {
          let indexOfAll = temp.indexOf("All");
          temp.splice(indexOfAll, 1);
        }
        temp.push(value);

      }
    }
    setActiveFunction([]);
    setCheckedTeacherList(temp);
    console.log(temp);

    academicStore.setAdvancedSelectedTeacherList(temp);
  }
  const onApplyAdvanceSearch = (dataInfo: any) => {
    var finalClassList;
    // if not display class codes in advance search modal, then assume class code is 'All'
    if (dataInfo.classCodeList.length == 1 && dataInfo.classCodeList[0] == "All") {
      var tempList = [];
      for (var c of filterStore.classList) {
        tempList.push(c.homeroomCode);
      }
      finalClassList = tempList;
    }
    else
      finalClassList = dataInfo.classCodeList;

    var finalDomainList = [];
    if (dataInfo.domainList.length == 1 && dataInfo.domainList[0] == "All") {
      for (var dl of filterStore.domainList) {
        finalDomainList.push(dl.domainDescription);
      }
    } else {
      for (var d of dataInfo.domainList) {
        for (var dl of filterStore.domainList) {
          if (+d == dl.id) {
            finalDomainList.push(dl.domainDescription);
            break;
          }
        }
      }
    }

    if (!dataInfo.semester && dataInfo.semester == 0) return;
    let reqData = {
      semesterIdList: [dataInfo.semester],
      homeRoomList: finalClassList,
      domainDescriptionList: finalDomainList,
      subjectClassIdList: []  // All subjects
    };

    academicStore.getSubmissionAnalysisData(reqData, true).then(() => {
      academicStore.getFeedbackAnalysisData(reqData, true)
        .then(() => {
          setShowAllTeachers(false);
          console.log("Teacher List", toJS(academicStore.resultTeacherList));

          setAllTeacherList(academicStore.resultTeacherList.map(data => data));
          setCheckedTeacherList(academicStore.resultTeacherList.map(data => data));
          academicStore.setAdvancedSelectedTeacherList(academicStore.resultTeacherList.map(data => data));
        });
    });

  }

  const toggleTeacherList = () => {
    let result;
    if (showAllTeachers) result = academicStore.resultTeacherList.map(data => data);
    else result = [];
    setCheckedTeacherList(result);
    academicStore.setAdvancedSelectedTeacherList(result);
    setShowAllTeachers(!showAllTeachers);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="m-4 mx-2">
      <div className="rounded-t-xl border border-b-0 p-4 text-barPrimary text-xl font-bold mt-4 grid grid-cols-2">
        <div className="py-2 uppercase">{t('FEEDBACK_ANALYSIS')}</div>
        <div className="text-right">
          <Button
            className="bg-buttonPrimary text-white p-2"
            component="span"
            onClick={() => setShowAdvanceSearch(true)}
          >
            {t('ADVANCED_SEARCH')}
          </Button>
        </div>
      </div>

      <Tabs
        className="border rounded-b-xl shadow-lg mb-2"
        TabIndicatorProps={{ style: { display: "none" } }}
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <Tab value={0} label={t('AVG_SUBMISSION')} {...feedbackTabProps(0, 0 === tabValue)} />
        <TabDivider />
        <Tab value={1}label={t('AVG_DUE_MARKING_DATE')} {...feedbackTabProps(1, 1 === tabValue)} />
        <TabDivider />
        <Tab value={2}label={t('AVG_FEEDBACK')} {...feedbackTabProps(2, 2 === tabValue)} />
        <TabDivider />
        {/* <Tab label="Subject Without Feedback" {...feedbackTabProps(3, 3 === tabValue)} /> */}
        <Tab value={3}label={t('TOTAL_WRITTEN_FEEDBACK')} {...feedbackTabProps(3, 3 === tabValue)} />
        <TabDivider />
        <Tab value={4} label={t('TOTAL_UNSUBMITTED_TASKS')} {...feedbackTabProps(4, 4 === tabValue)} />

      </Tabs>

      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('AVG_SUBMISSION_TO_MARKING_DAYS_BY_CLASS_TEACHER')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              filterStore.loading || academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byMarkingDaysLabel.length > 0 ? byMarkingDaysLabel : ["No Result"],
                      values: byMarkingDaysAmount.length > 0 ? byMarkingDaysAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
        </VMCard>

      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={1}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('AVG_OF_DAYS_BTW_DUE_DATE_AND_MARKING_DATE_BY_CLASS_TEACHER')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byDueDateMarkingDateLabel.length > 0 ? byDueDateMarkingDateLabel : ["No Result"],
                      values: byDueDateMarkingDateAmount.length > 0 ? byDueDateMarkingDateAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>

        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={2}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('AVG_FEEDBACK_PER_STUDENT_BY_CLASS_TEACHER')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: perStudentByClassLabel.length > 0 ? perStudentByClassLabel : ["No Result"],
                      values: perStudentByClassAmount.length > 0 ? perStudentByClassAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
        </VMCard>
      </TabPanel>

      {/* <TabPanel value={tabValue} paddingValue={0} index={3}>
          <div className="w-full text-center text-barPrimary">
            Subject with No Feedback yet by Class Teacher
          </div>
          <div className="overflow-auto" style={{ maxHeight: CURRENT_CHART_HEIGHT }}>
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <table className="mt-6 w-full">
                  <thead>
                    <tr className="border-t border-b">
                      <td className="py-2 text-xs">ClassCode</td>
                      <td className="py-2 text-xs">ClassTeacher</td>
                      <td className="py-2 text-xs">DomainDescription</td>
                      <td className="py-2 text-xs text-right">AnyFeedbackType</td>
                      <td className="py-2 text-xs text-right">Count of UID</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      academicStore.feedbackTableList.map((record, index) => (
                        <tr className="border-t border-b" key={`feedback_table_${index}`}>
                          <td className="pr-2 text-xs">{record.classCode}</td>
                          <td className="pr-2 text-xs">{record.classTeacher}</td>
                          <td className="pr-2 text-xs">{record.domainDescription}</td>
                          <td className="pr-2 text-xs text-right">{record.anyFeedbackType}</td>
                          <td className="pr-2 text-xs text-right">{record.countOfUid}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                  <tfoot>
                    <tr className="border-t border-b">
                      <td className="pr-2 text-xs font-bold">Total</td>
                      <td className="pr-2 text-xs"></td>
                      <td className="pr-2 text-xs"></td>
                      <td className="pr-2 text-xs text-right">{feedbackTypeCount}</td>
                      <td className="pr-2 text-xs text-right">{uidCount}</td>
                    </tr>
                  </tfoot>
                </table>
            }
          </div>
        </TabPanel> */}

      <TabPanel value={tabValue} paddingValue={0} index={3}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('TOTAL_WRITTEN_FEEDBACK_BY_CLASS_TEACHER')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESStackedVBar
                  dataAndSetting={{
                    data: {
                      names: writtenFeedbackLabel.length > 0 ? writtenFeedbackLabel : ["No Result"],
                      legends: writtenFeedbackCat,
                      values: writtenFeedbackAmount.length > 0 ? writtenFeedbackAmount : [[0], [0], [0]],
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                    xAxisLabelRotate: 20,
                  }}
                />
            }
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={4}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: nonSubmissionByTeacherLabel.length > 0 ? nonSubmissionByTeacherLabel : ["No Result"],
                      values: nonSubmissionByTeacherAmount.length > 0 ? nonSubmissionByTeacherAmount : [0]
                    },
                    height: CURRENT_CHART_HEIGHT,
                    width: CHART_WIDTH,
                    showDataZoom: false,
                  }}
                />
            }
          </div>
        </VMCard>
      </TabPanel>

      <AdvanceSearchModal
        showModal={showAdvanceSearch}
        closeModal={() => setShowAdvanceSearch(false)}
        showYearLevelBar={true}
        showAcademicYear={true}
        showSemester={true}
        showYearLevel={true}
        showHomeClass={true}
        otherFilter={["domain"]}
        className="w-full attendance"
        onApplyFilter={onApplyAdvanceSearch}
      >
        {
          academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            allTeacherList.length > 0 ?
              <div className="grid grid-cols-4">
                <div className="overflow-auto" style={{ maxHeight: '100vh' }}>
                  <div className="bg-barPrimary p-2 text-white mb-4 w-full">
                    Selected Teachers
                  </div>

                  <div className="text-right">
                    <button
                      type="button"
                      className="bg-barPrimary text-white mb-2 mx-2 p-1 rounded-md text-sm"
                      onClick={toggleTeacherList}
                    >
                      {showAllTeachers ? 'Show All' : 'Clear All'}
                    </button>
                  </div>

                  {academicStore.resultTeacherList.length > 0 ?
                    academicStore.resultTeacherList.
                      slice().sort().map((teacher, index) => (
                        <div>
                          <Checkbox
                            key={`sub_teacher_list_${index}`}
                            value={teacher}
                            onChange={handleTeacherChecks}
                            checked={checkedTeacherList.includes(teacher) ? true : false}
                          />
                          <span className="text-sm">
                            {/* {teacher.substring(teacher.indexOf(" ") + 1, teacher.length)} */}
                            {teacher}
                          </span>
                        </div>
                      ))
                    : <Typography>{t('NO_RESULT_FOUND')}</Typography>}
                </div>
                <div className="col-span-3 overflow-auto" style={{ maxHeight: '100vh' }}>
                  <StudentTasksAdvancedSearch />
                </div>
              </div>
              : <div className="text-center py-8"><Typography>{t('NO_RESULT')}</Typography></div>}
      </AdvanceSearchModal>
    </div>


  )
})

export default FeedbackAnalysis;