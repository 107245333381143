import { Button, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Treemap, Tooltip, BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Legend, Brush } from "recharts";
import { BAR_RADIUS, CHART_HEIGHT, CHART_WIDTH, TAG_STYLE, THEME_CLASS } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import IESAdvanceStackedBar from "../charts/iesAdvancedStackedBar";
import IESBar from "../charts/iesBar";
import IESTreeMap from "../charts/iesTreeMap";
import TabPanel from "../tab-panel";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SlideDown from "react-slidedown";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';
import IESVBar from "../charts/iesVBar";
import VMCard from "../collapsed-card";
import IESStackedBarChart from "../charts/IESStackedBarChart";
import FilterDisplay from "./se-filter-display";
import TabDivider from "../tab-divider";

const COLORS = [
  "#05b8aa",
  "#dfbfbf",
  "#a76999",
  "#f4d25a",
  "#badbee",
  "#52354c",
  "#114514"
];

const CustomizedContent = (props: any) => {
  const { root, depth, x, y, width, height, index, colors, name, value, num } = props;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill:
            depth < 2
              ? colors[index % colors.length]
              : "none",
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + 10}
          y={y + height - 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}
        >
          {num}
        </text>
      ) : null}
      {depth === 1 ? (
        <text x={x + 4} y={y + 18} fill="#fff" fontSize='12px' fillOpacity={0.9}>
          {name}
        </text>
      ) : null}
    </g>
  );
};

function tabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const SubmissionAnalysis = observer(({ callback, showSlideDown }) => {
  const { t } = useTranslation();
  const headingStyle = "text-barPrimary p-4 text-lg 2xl:text-2xl font-bold";
  const { academicStore, filterStore } = useStores();
  const [nonSubmissionByTeacherList, setNonSubmissionByTeacherList] = useState<any>([]);
  const [nonSubmissionByDomainLabel, setNonSubmissionByDomainLabel] = useState<any>([]);
  const [nonSubmissionByDomainList, setNonSubmissionByDomainList] = useState<any>([]);
  const [countNonSubmissionLabel, setCountNonSubmissionLabel] = useState<any>([]);
  const [countNonSubmissionValue, setCountNonSubmissionValue] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);
  const [tempTree, setTempTree] = useState<any>();
  const [treeValue, setTreeValue] = useState<any>([]);
  const [treeLabel, setTreeLabel] = useState<any>([]);
  const [submissionStatusData, setSubmissionStatusData] = useState<any>([]);
  const [submissionXAxis, setSubmissionXAxis] = useState<any>([]);
  const [submissionStatusMode, setSubmissionStatusMode] = useState<string>("");
  const [refresh, setRefresh] = useState<any>([]);

  useEffect(() => {
    if (academicStore.nonSubmissionByTeacherSAList.length > 0) {
      var temp: any[] = [];
      var tree: any[] = [];
      var tempTreeValue = [];
      var tempTreeLabel = [];
      var theme = typeof window !== "undefined" && localStorage.THEME ? localStorage.THEME : 'theme-primary';
      var themeClass: any = THEME_CLASS.find((data) => data.theme == theme);
      academicStore.nonSubmissionByTeacherSAList.filter(item => item.amount > 0).map((item: any, index: number) => {
        temp.push({
          name: item.label,
          num: item.amount
        });
        tree.push({
          name: item.label.substring(item.label.indexOf(" ") + 1, item.label.length) + ": " + item.amount,
          value: item.amount
        })
        tempTreeValue.push({
          value: +item.amount,
          itemStyle: {
            color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
          }
        });
        tempTreeLabel.push(item.label.substring(item.label.indexOf(" ") + 1, item.label.length) + ": " + item.amount);
      })
      // Only show first 10 records;
      temp.splice(10);
      console.log("Tree", toJS(tree));
      setTreeLabel(tempTreeLabel);
      setTreeValue(tempTreeValue);
      setTempTree(tree);
      setNonSubmissionByTeacherList(temp);

    }
  }, [academicStore.nonSubmissionByDomainTeacherSAList]);

  useEffect(() => {
    if (academicStore.nonSubmissionByDomainTeacherSAList.length > 0) {
      var value = [];
      var label = [];
      var index = 0;
      academicStore.nonSubmissionByDomainTeacherSAList.map((record) => {
        label.push(record.category);
        record.detailData.map((data) => {
          var tempData = [];
          for (var i = 0; i < academicStore.nonSubmissionByDomainTeacherSAList.length; i++) {
            tempData.push(0);
          }
          tempData[index] = data.amount;

          value.push({
            name: data.label,
            type: 'bar',
            stack: 'total',
            emphasis: {
              focus: 'series'
            },
            data: tempData
          })
        })
        index++;
      })
      setNonSubmissionByDomainList(value);
      setNonSubmissionByDomainLabel(label);
    }
  }, [academicStore.nonSubmissionByDomainTeacherSAList]);

  useEffect(() => {

    var tempLabel: any[] = [];
    var tempAmount: any[] = [];
    var theme = typeof window !== "undefined" && localStorage.THEME ? localStorage.THEME : 'theme-primary';
    var themeClass: any = THEME_CLASS.find((data) => data.theme == theme);
    if (academicStore.countNonSubmissionSAList.length > 0) {
      academicStore.countNonSubmissionSAList.map((record: any, index: number) => {
        tempLabel.push(record.label);
        tempAmount.push({
          value: record.amount,
          itemStyle: {
            color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
          }
        });
      })
    }
    setCountNonSubmissionLabel(tempLabel);
    setCountNonSubmissionValue(tempAmount);
    console.log(tempAmount);

  }, [academicStore.countNonSubmissionSAList])

  useEffect(() => {
    setSubmissionStatusData([]);
    if (academicStore.submissionStatusData.length > 0) {
      let currentCat = academicStore.submissionStatusData[0].taskDetail[0].label
      setSubmissionStatusMode(currentCat);
      setSubmissionXAxis(academicStore.submissionStatusData.map(stu => stu.studentName));
      onChangeSubmissionDisplayMode(currentCat);
    }
    setRefresh([]);
  }, [academicStore.submissionStatusData])

  const onChangeSubmissionDisplayMode = (mode: string) => {
    let onTimeSubmission: any[] = [];
    let nonSubmission: any[] = [];
    let notYetSubmitted: any[] = [];
    let overDueSubmission: any[] = [];

    for (var task of academicStore.submissionStatusData) {
      if (task.taskDetail.find(task => task.label === mode)) {
        onTimeSubmission.push(
          task.taskDetail.find(task => task.label === mode).onTimeSubmission > 0 ? task.taskDetail.find(task => task.label === mode).onTimeSubmission
            : { value: task.taskDetail.find(task => task.label === mode).onTimeSubmission, label: { show: false } });
        nonSubmission.push(
          task.taskDetail.find(task => task.label === mode).nonSubmission > 0 ? task.taskDetail.find(task => task.label === mode).nonSubmission
            : { value: task.taskDetail.find(task => task.label === mode).nonSubmission, label: { show: false } });
        notYetSubmitted.push(
          task.taskDetail.find(task => task.label === mode).notYetSubmitted > 0 ? task.taskDetail.find(task => task.label === mode).notYetSubmitted
            : { value: task.taskDetail.find(task => task.label === mode).notYetSubmitted, label: { show: false } });
        overDueSubmission.push(
          task.taskDetail.find(task => task.label === mode).overDueSubmission > 0 ? task.taskDetail.find(task => task.label === mode).overDueSubmission
            : { value: task.taskDetail.find(task => task.label === mode).overDueSubmission, label: { show: false } });
      }

    }
    let tempSubmissionData = [
      {
        name: "On Time Submission",
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: onTimeSubmission,
        // data: academicStore.submissionStatusData.map(stu => stu.taskDetail.find(task => task.label === mode).onTimeSubmission)
      },
      {
        name: "Non Submission",
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: nonSubmission
        // data: academicStore.submissionStatusData.map(stu => stu.taskDetail.find(task => task.label === mode).nonSubmission)
      },
      {
        name: "Not Yet Submitted",
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: notYetSubmitted
        // data: academicStore.submissionStatusData.map(stu => stu.taskDetail.find(task => task.label === mode).notYetSubmitted)
      },
      {
        name: "Over Due Submission",
        type: 'bar',
        stack: 'total',
        label: { show: true },
        emphasis: { focus: 'series' },
        data: overDueSubmission,
        // data: academicStore.submissionStatusData.map(stu => stu.taskDetail.find(task => task.label === mode).overDueSubmission)
      },
    ]
    console.log("Submission Status", tempSubmissionData);
    setSubmissionStatusData(tempSubmissionData);
    setSubmissionStatusMode(mode);
    setRefresh([]);
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="m-4 mx-2">

      <div className="rounded-t-xl border border-b-0 p-4 text-barPrimary text-xl font-bold mt-4 grid grid-cols-2">
        <div className="py-2 uppercase">{t('SUBMISSION_ANALYSIS')}</div>
      </div>
      <Tabs
        className="border rounded-b-xl shadow-lg mb-2"
        TabIndicatorProps={{ style: { display: "none" } }}
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <Tab value={0} label={t('STUDENT_TASK_ADHERENCE')} {...tabProps(0, 0 === tabValue)} />
        <TabDivider />
        <Tab value={1} label={t('NON_SUBMISSION')} {...tabProps(1, 1 === tabValue)} />
        <TabDivider />
        <Tab value={2} label={t('UNSUBMITTED_TASKS_BY_SUBJECT')} {...tabProps(2, 2 === tabValue)} />
        <TabDivider />
        <Tab value={3} label={t('UNSUBMITTED_TASKS_BY_STUDENT')} {...tabProps(3, 3 === tabValue)} />
        <TabDivider />
        <Tab value={4} label={t('TOTAL_NON_SUBMISSION_REASON')} {...tabProps(4, 4 === tabValue)} />
      </Tabs>

      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('STUDENT_TASK_ADHERENCE')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                submissionStatusData.length > 0 ?
                  <>
                    <div className="text-right">
                      {
                        academicStore.submissionStatusData[0].taskDetail.map((task) => (
                          <Button
                            className={`rounded-lg text-white bg-barPrimary text-sm m-4 ml-0 mt-0`}
                            component="span"
                            onClick={() => onChangeSubmissionDisplayMode(task.label)}
                            disabled={submissionStatusMode === task.label}
                          >
                            {submissionStatusMode === task.label ? `${t('CURRENT')}: ${task.label}` : task.label}
                          </Button>
                        ))
                      }
                    </div>
                    <IESStackedBarChart
                      setting={{
                        series: submissionStatusData,
                        xAxis: submissionXAxis,
                        height: 400,
                        width: CHART_WIDTH,
                      }}
                    />
                  </>
                  : <div className="text-center text-barPrimary">{t('NO_RESULT')}</div>
            }
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={1}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('NON_SUBMISSION_BY_CLASS_TEACHER')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                nonSubmissionByTeacherList.length > 0 ?
                  <div className="max-h-96 overflow-y-auto overflow-x-hidden">
                    <IESBar
                      dataAndSetting={{
                        data: {
                          names: treeLabel.length > 0 ? treeLabel : [],
                          values: treeValue.length > 0 ? treeValue : [],
                        },
                        height: treeLabel.length > 15 ? treeLabel.length * 20 : 300,
                        width: CHART_WIDTH,
                        showDataZoom: false,
                      }}
                    />
                    {/* <IESTreeMap
                    setting={
                      {
                        data: tempTree,
                        height: 400,
                        width: '100%'
                      }
                    }
                  /> */}
                  </div>
                  : <div className="text-center text-barPrimary">{t('NO_RESULT')}</div>
            }
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={2}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('TASKS_LISTED_SIMON_PAM_AS_UNSUBMITTED_BY_SUBJECT_DOMAIN')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            <div className="max-h-96 overflow-auto">
              {
                academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <IESBar
                    dataAndSetting={{
                      data: {
                        names: countNonSubmissionLabel.length > 0 ? countNonSubmissionLabel : [t('NO_RESULT')],
                        values: countNonSubmissionValue.length > 0 ? countNonSubmissionValue : [0]
                      },
                      height: countNonSubmissionLabel.length > 15 ? countNonSubmissionLabel.length * 20 : 300,
                      width: CHART_WIDTH,
                      showDataZoom: false,
                    }}
                  />
              }
            </div>
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={3}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('UNSUBMITTED_TASKS_BY_STUDENT')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            <div className="p-4 overflow-auto" style={{ maxHeight: 600 }}>
              {
                academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  academicStore.unsubmittedTaskSAList.length > 0 ?
                    <table className="w-full">
                      <thead className="bg-barPrimary text-white">
                        <td className="p-2 font-bold">{t('NAME')}</td>
                        <td className="p-2 font-bold">{t('DOMAIN')}</td>
                        <td className="p-2 font-bold">{t('TASK')}</td>
                        <td className="p-2 font-bold">{t('REASON')}</td>
                        <td className="p-2 font-bold">{t('DUE_DATE')}</td>
                      </thead>
                      <tbody>
                        {
                          academicStore.unsubmittedTaskSAList.map((record, index) => (
                            <tr key={`unsubmitted_task_sa_list_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                              <td className="p-2 text-sm">{record.name}</td>
                              <td className="p-2 text-sm">{record.domain}</td>
                              <td className="p-2 text-sm">{record.taskTitle}</td>
                              <td className="p-2 text-sm">{record.reason}</td>
                              <td className="p-2 text-sm">{record.dueDate.substring(0, 10)}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                    :
                    <div className="w-full text-center text-lg text-barPrimary">{t('NO_RESULT_FOUND')}</div>
              }
            </div>
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={4}>
        <VMCard titleComponent={
          <div className={headingStyle}>
            {t('COUNT_NON_SUBMISSION_REASON_BY_STUDENT')}
          </div>
        }>
          <FilterDisplay />
          <div className="mt-4 mb-8">
            <div className="max-h-96 overflow-auto">
              {
                academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                  :
                  <IESBar
                    dataAndSetting={{
                      data: {
                        names: countNonSubmissionLabel.length > 0 ? countNonSubmissionLabel : [t('NO_RESULT')],
                        values: countNonSubmissionValue.length > 0 ? countNonSubmissionValue : [0]
                      },
                      height: countNonSubmissionLabel.length > 15 ? countNonSubmissionLabel.length * 20 : 300,
                      width: CHART_WIDTH,
                      showDataZoom: false,
                    }}
                  />
              }
            </div>
          </div>
        </VMCard>
      </TabPanel>

    </div>

  )
})

export default SubmissionAnalysis;