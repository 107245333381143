import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { BAR_RADIUS } from '../../constants/style';

const IESAdvanceClusteredBar = ({ dataAndSetting, source }) => {
  const [option, setOption] = useState({});
  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (source.length > 0) {
      var tempList: any[] = [];
      var count = 0;
      for (var s of source) {
        if (count == 0) {
          count++;
          continue;
        }
        tempList.push(
          {
            type: 'bar',
            seriesLayoutBy: 'row',
            itemStyle: {
              // barBorderRadius: [BAR_RADIUS, BAR_RADIUS, 0, 0]
            }
          })
      }
      setSeries(tempList);
    }
  }, [source])

  const configureOption = (data, setting) => {

    var dataZoomStart = Math.round((data.names.length - setting.initialShow) / data.names.length * 100);
    dataZoomStart = (dataZoomStart < 0) ? 0 : dataZoomStart;

    var option_ = {
      legend: {},
      tooltip: {},
      dataset: {
        source: source
      },
      xAxis: [
        { type: 'category', gridIndex: 0 },
      ],
      yAxis: [
        { gridIndex: 0 },
      ],
      grid: [
        { bottom: '55%' },
      ],
      series: series
    }
    setOption(option_);
  }

  useEffect(() => {
    const {
      initialShow = 10,
      barColor = '#05b8aa',
      barWidth = '70%',
      barMaxWidth = 25,
    } = dataAndSetting
    configureOption(dataAndSetting.data, { initialShow, barColor, barWidth, barMaxWidth })
  }, [dataAndSetting]);

  return (
    <ReactEcharts
      style={{
        height: dataAndSetting.height,
        width: dataAndSetting.width,
        margin: '0 auto',
      }}
      option={option}
    />
  );
}

export default IESAdvanceClusteredBar;