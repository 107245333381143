import React, { useEffect, useState } from 'react';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import NotificationsIcon from '@material-ui/icons/NotificationsNone';
import ExitToAppOutlined from '@material-ui/icons/ExitToAppOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { observer } from 'mobx-react';
import { Button, CircularProgress } from '@material-ui/core';
import { Link } from 'gatsby';
import useStores from '../hooks/use-stores';
import CustomisedModal from './customised-modal';
import { useMsal } from "@azure/msal-react";
import { Language, signInMethod } from '../constants/options';
import LanguageIcon from '@material-ui/icons/Language';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const TabDivider = observer(({ }) => {
  const { userStore, maintenanceStore } = useStores();

  return (
    <div className="relative">
      <div className="absolute top-1/2 text-gray-400"
        style={{ transform: 'translate(-50%, -50%)' }}>
        <div className="bg-gray-400" style={{ height: 15, width: 2, background: 'lightgray' }}></div>
      </div>
    </div>
  );
});

export default TabDivider;
