import { observer } from "mobx-react"
import React, { useState } from 'react';
import useStores from "../../hooks/use-stores"
import Footer from "../footer"
import Header from "../header"
import MenuBar from "../menu"
import FilterElement from "../filter-element";
import Button from '@material-ui/core/Button';
import Filter from "../filter";
import PermissionGuard from "../permission-guard";
import { navigate } from "gatsby";
import { TAG_STYLE } from "../../constants/style";
import { useTranslation } from "react-i18next";

const FilterDisplay = observer(({ }) => {
  const { t } = useTranslation();
  const { filterStore } = useStores();

  return (
    <div className="mt-4 text-center w-full">
      <div className={`px-4 text-sm text-barPrimary font-semibold text-base`}>
        {t('YEAR')} {filterStore.selectedYear}
        <span className="mx-1">-</span> 
        {filterStore.selectedSemester.includes('Semester') ? filterStore.selectedSemester : `${t('SEMESTER')} ${filterStore.selectedSemester}`}
        <span className="mx-1">-</span>
        {filterStore.selectedHomeroom == t('ALL') ? t('ALL_HOME_ROOMS') : filterStore.selectedHomeroom}
        <span className="mx-1">-</span>
        {filterStore.selectedDomain == t('ALL') ? t('ALL_DOMAINS') : filterStore.selectedDomain}
        <span className="mx-1">-</span>
        {filterStore.selectedSubject == t('ALL') ? t('ALL_SUBJECTS') : filterStore.selectedSubject}
        <span className="mx-1">-</span>
        {filterStore.selectedClass == t('ALL') ? t('ALL_CLASSES') : filterStore.selectedClass}
      </div>
    </div>
  )
})

export default FilterDisplay;