import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { toJS } from 'mobx';

const IESClusteringDotGraph = observer(({ setting }) => {
  const { t } = useTranslation();

  var option = {
    xAxis: {
      toolTip: { show: true },
      name: "Average Line",
      nameLocation: "start",
      nameGap: 50,
      nameRotate: 90,
      nameTextStyle: { fontSize: 14 }
    },
    yAxis: {
      toolTip: { show: true },
    },
    grid: {
      top: '3%',
      bottom: '10%',
    },
    tooltip: {
      position: 'top',
      formatter: function (value) {
        let growth = value.data.value[0];
        let performance = value.data.value[1];
        console.log(toJS(setting.prevPerformance));
        
        return `<b>${value.data.name}</b><br/> 
        <div>Previous Performance: <b>${(setting.prevPerformance.find(s => s.name == value.data.name).previousPerformance)}</b></div>
        <div style='${growth < 0 ? 'color:red;' : 'color:green;'}'>Growth: <b>${growth}%</b></div>
        <div>Performance: <b>${(performance + setting.averageScore).toFixed(2)} </b></div>
        <div>Average Score: <b>${setting.averageScore.toFixed(2)} </b></div>
        <div style='${performance < 0 ? 'color:red;' : 'color:green;'}'>${performance >= 0 ? "Above Average" : "Below Average"}: <b>${performance >= 0 ? "+" : ""}${performance}</b></div>
        <div>Student Status: <b>${growth >= 0 && performance >= 0 ? "High Achievement/High Growth"
            : growth >= 0 && performance < 0 ? "Low Achievement/High Growth"
              : growth < 0 && performance >= 0 ? "High Achievement/Low Growth"
                : "Low Achievement/Low Growth"}</b></div>`
      },
    },
    series: [
      {
        symbolSize: 20,
        data: setting.data,
        type: 'scatter'
      }
    ]
  }

  return (
    <>
      <ReactEcharts
        style={{
          height: setting.height,
          width: setting.width,
          paddingTop: 10,
        }}
        option={option}
      />
    </>
  );
});

export default IESClusteringDotGraph;