import { Button, CircularProgress, Tab, Tabs } from "@material-ui/core";
import { observer } from "mobx-react";
import React, { useState, useEffect } from "react";
import { BAR_RADIUS, CHART_BAR_COLOR, CHART_BAR_WIDTH, CHART_HEIGHT, CHART_WIDTH, TAG_STYLE, THEME_CLASS } from "../../constants/style";
import useStores from "../../hooks/use-stores";
import AdvanceSearchModal from "../advance-search-modal";
import IESAdvanceClusteredBar from "../charts/iesAdvanceClusteredBar";
import IESBar from "../charts/iesBar";
import TabPanel from "../tab-panel";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SlideDown from "react-slidedown";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';
import VMCard from "../collapsed-card";
import FilterDisplay from "./se-filter-display";
import TabDivider from "../tab-divider";

function tabProps(index, isActive) {
  return {
    id: `simple-tab-1-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    className: `normal-case ${isActive ? 'text-barPrimary' : 'text-black'} `
  };
}

const OrderOfStudents = observer(({ callback, showSlideDown }) => {
  const { t } = useTranslation();
  const headingStyle = "text-barPrimary p-4 pb-0 text-lg 2xl:text-2xl font-bold";
  const { academicStore, filterStore } = useStores();
  const [year, setYear] = useState("");
  const [byPercentageLabel, setByPercentageLabel] = useState<any>([]);
  const [byPercentageValue, setByPercentageValue] = useState<any>([]);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState<boolean>(false);
  const [topTenLabel, setTopTenLabel] = useState<any>([]);
  const [topTenValue, setTopTenValue] = useState<any>([]);
  const [topTenLabelAdvance, setTopTenLabelAdvance] = useState<any>([]);
  const [topTenValueAdvance, setTopTenValueAdvance] = useState<any>([]);
  const [distributionValue, setDistributionValue] = useState<any>([]);
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    setTopTenLabel([]);
  }, [])

  const onApplyAdvanceSearch = (dataInfo: any) => {
    setYear(dataInfo.year);
    var finalClassList;
    if (dataInfo.classCodeList.length == 1 && dataInfo.classCodeList[0] == "All") {
      var tempList = [];
      for (var c of filterStore.classList) {
        tempList.push(c.homeroomCode);
      }
      finalClassList = tempList;
    }
    else
      finalClassList = dataInfo.classCodeList;

    var finalDomainList = [];
    if (dataInfo.domainList.length == 1 && dataInfo.domainList[0] == "All") {
      for (var dl of filterStore.domainList) {
        finalDomainList.push(dl.domainDescription);
      }
    } else {
      for (var d of dataInfo.domainList) {
        for (var dl of filterStore.domainList) {
          if (+d == dl.id) {
            finalDomainList.push(dl.domainDescription);
            break;
          }
        }
      }
    }

    if (!dataInfo.semester && dataInfo.semester == 0) return;
    let reqData =
    {
      semesterIdList: [dataInfo.semester],
      homeRoomList: finalClassList,
      domainDescriptionList: finalDomainList,
      subjectClassIdList: []  // All subjects
    };

    console.log(reqData);

    academicStore.getMarkDistributionByHomeClass(reqData, true);
    academicStore.getAveragePercentageByStudent(reqData, true);
  }

  useEffect(() => {
    if (academicStore.averagePercentageByStudent.length > 0) {
      var tempLabels: any[] = [];
      var tempAmount: any[] = [];
      var theme = typeof window !== "undefined" && localStorage.THEME ? localStorage.THEME : 'theme-primary';
      var themeClass: any = THEME_CLASS.find((data) => data.theme == theme);
      academicStore.averagePercentageByStudent.map((record: any, index: number) => {
        tempLabels.push(record.name);
        tempAmount.push({
          value: record.averagePercentage,
          itemStyle: {
            color: themeClass.chartColorLibrary[index % themeClass.chartColorLibrary.length]
          }
        });
      })

      setByPercentageLabel(tempLabels.reverse());
      setByPercentageValue(tempAmount.reverse());

      var tempLabel = [];
      var tempValue = [];
      var count = 0;
      for (var record of academicStore.averagePercentageByStudent) {
        if (count < 10) {
          tempLabel.push(record.name);
          tempValue.push(record.averagePercentage);
        } else {
          break;
        }
        count++;
      }
      setTopTenLabel(tempLabel);
      setTopTenValue(tempValue);
    }
  }, [academicStore.averagePercentageByStudent]);

  useEffect(() => {

    if (academicStore.markDistributionByHomeClass.length > 0) {
      // let tempScoreInfo = [];
      var legend = [["product"]];
      for (var record of academicStore.markDistributionByHomeClass[0].data) {
        legend[0].push(record.label);
      }

      academicStore.markDistributionByHomeClass.map((record) => {
        var temp = [record.homeClass];
        record.data.map((data) => {
          temp.push(+data.amountString);
        })
        legend.push(temp);
      });
      setDistributionValue(legend);
    }
  }, [academicStore.markDistributionByHomeClass]);


  useEffect(() => {
    if (academicStore.averagePercentageByStudentAdvance.length > 0) {
      var tempLabel:any[] = [];
      var tempValue:any[] = [];
      for (var i = 0; i < 10; i++) {
        tempLabel.push(academicStore.averagePercentageByStudentAdvance[i].name);
        tempValue.push({
          value: academicStore.averagePercentageByStudentAdvance[i].averagePercentage,
          itemStyle: {
            color: THEME_CLASS[0].chartColorLibrary[i % THEME_CLASS[0].chartColorLibrary.length],
            // barBorderRadius: [0, BAR_RADIUS, BAR_RADIUS, 0]
          }
        });
      }
      setTopTenLabelAdvance(tempLabel.reverse());
      setTopTenValueAdvance(tempValue.reverse());
    }
  }, [academicStore.averagePercentageByStudentAdvance]);

  useEffect(() => {

    if (academicStore.markDistributionByHomeClassAvance.length > 0) {
      // let tempScoreInfo = [];
      var legend = [["product"]];
      for (var record of academicStore.markDistributionByHomeClassAvance[0].data) {
        legend[0].push(record.label);
      }

      academicStore.markDistributionByHomeClassAvance.map((record) => {
        var temp = [record.homeClass];
        record.data.map((data) => {
          temp.push(+data.amountString);
        })
        legend.push(temp);
      });
      console.log(legend);

      setDistributionValue(legend);
    }
  }, [academicStore.markDistributionByHomeClassAvance]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div className="m-4 mx-2">

      <div className="rounded-t-xl border border-b-0 p-4 text-barPrimary text-xl font-bold mt-4 grid grid-cols-2">
        <div className="py-2 uppercase">{t('ORDER_STUDENTS_IN_YEAR_LEVEL')}</div>
        <div className="text-right">
          <Button
            className="bg-buttonPrimary text-white p-2"
            component="span"
            onClick={() => setShowAdvanceSearch(true)}
          >
            {t('ADVANCED_SEARCH')}
          </Button>
        </div>
      </div>

      <Tabs
        className="border rounded-b-xl shadow-lg mb-2"
        TabIndicatorProps={{ style: { display: "none" } }}
        value={tabValue}
        onChange={handleTabChange}
        variant="fullWidth"
        scrollButtons="auto"
      >
        <Tab value={0} label={t('AVG_SCORE_GRAPH')}{...tabProps(0, 0 === tabValue)} />
        <TabDivider />
        <Tab value={1} label={t('AVG_SCORE_TABLE')} {...tabProps(1, 1 === tabValue)} />
      </Tabs>

      <TabPanel value={tabValue} paddingValue={0} index={0}>
        <VMCard titleClassName="mb-4" titleComponent={
          <div className={headingStyle}>
            {t('AVG_SCORE_AND_FIRST_TASK_TITLE_BY_NAME')}
          </div>
        }>
          <FilterDisplay />
          <div className="mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                :
                <IESBar
                  dataAndSetting={{
                    data: {
                      names: byPercentageLabel.length > 0 ? byPercentageLabel : [t('NO_RESULT')],
                      values: byPercentageValue.length > 0 ? byPercentageValue : [0]
                    },
                    height: 500,
                    width: CHART_WIDTH,
                    initialShow: 300
                  }}
                />
            }
          </div>
        </VMCard>
      </TabPanel>

      <TabPanel value={tabValue} paddingValue={0} index={1}>
        <VMCard titleClassName="mb-4" titleComponent={
          <div className={headingStyle}>
            {t('AVG_SCORE_TABLE')}
          </div>
        }>
          <FilterDisplay />
          <div className="mb-8">
            {
              academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
                : academicStore.averagePercentageByStudent.length > 0 ?
                  <div className="overflow-auto px-4" style={{ maxHeight: 500 }}>
                    <table className="mt-6 w-full">
                      <thead className="bg-barPrimary text-white">
                        <th className="text-left p-2">UID</th>
                        {/* <th className="">Preferred</th> */}
                        <th className="text-left p-2">{t('NAME')}</th>
                        <th className="text-right p-2">{t('AVG_SCORE')}</th>
                        {/* <th className="">YearDescription</th> */}
                      </thead>
                      <tbody>
                        {academicStore.averagePercentageByStudent.map((record, index) => (
                          <tr key={`average_percent_by_student_${record.ui}_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                            <td className="p-2">{record.uid}</td>
                            {/* <td className="">{record.preferred}</td> */}
                            <td className="p-2">{record.name}</td>
                            <td className="text-right p-2">
                              {record.averagePercentage}
                            </td>
                            {/* <td className=" text-right">{record.yearDescription}</td> */}
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                  :
                  <div className="w-full text-center text-lg py-8 text-barPrimary">{t('NO_RESULT')}</div>
            }
          </div>
        </VMCard>
      </TabPanel>


      <AdvanceSearchModal
        showModal={showAdvanceSearch}
        closeModal={() => setShowAdvanceSearch(false)}
        showAcademicYear={true}
        showSemester={true}
        showYearLevel={true}
        otherFilter={["domain"]}
        onApplyFilter={onApplyAdvanceSearch}
      >
        {
          !year && <div className="w-full text-barPrimary text-3xl text-center p-10">{t('CHART_AREA')}</div>
        }

        {
          academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
            :
            year && topTenLabelAdvance.length > 0 &&
            <div className="pt-10">
              <div className="text-center text-barPrimary text-xl">
                {t('TOP_10_STUDENTS_IN')}{year}
              </div>
              <IESBar
                dataAndSetting={{
                  data: {
                    names: topTenLabelAdvance.length > 0 ? topTenLabelAdvance : [t('NO_RESULT')],
                    values: topTenValueAdvance.length > 0 ? topTenValueAdvance : [0]
                  },
                  height: 400,
                  width: CHART_WIDTH,
                  initialShow: 10
                }}
              />
            </div>
        }
        <div className="w-full mt-10 px-2">
          {
            academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              <>
                {
                  year &&
                  <>
                    <div className="text-center text-barPrimary text-xl mb-8">
                      {t('MARK_DISTRIBUTION')}
                    </div>
                    <div className="overflow-auto" style={{ maxHeight: 500 }}>
                      <table className="w-full">
                        <thead className="bg-barPrimary text-white text-sm">
                          <th className="text-left p-2">{t('ROOM_CODE')}</th>
                          <th className="text-right p-2">{t('HIGH_SCORE')}</th>
                          <th className="text-right p-2">{t('LOW_SCORE')}</th>
                          <th className="text-right p-2">{t('AVG_SCORE')}</th>
                          <th className="text-right p-2">{t('MEDIUM_SCORE')}</th>
                          <th className="text-right p-2">{t('TOTAL_STUDENTS')}</th>
                        </thead>
                        <tbody>
                          {
                            academicStore.markDistributionByHomeClassAvance.length > 0 &&
                            academicStore.markDistributionByHomeClassAvance.map((record, index) => (
                              <tr key={`mark_distribution_${index}`} className={`${index % 2 != 0 && 'bg-gray-50'}`}>
                                <td className="p-2">{record.homeClass}</td>
                                <td className="text-right p-2">{record.highestScore}</td>
                                <td className="text-right p-2">{record.lowestScore}</td>
                                <td className="text-right p-2">{record.averageScore}</td>
                                <td className="text-right p-2">{record.mediunScore}</td>
                                <td className="text-right p-2">{record.totalStudent}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </>
                }

              </>
          }
        </div>
        <div className="mt-8 overflow-auto">
          {
            academicStore.loading ? <div className="w-full text-center p-20"><CircularProgress className="text-barPrimary" /></div>
              :
              year &&
              <IESAdvanceClusteredBar
                dataAndSetting={{
                  data: {
                    names: ["No result"],
                    values: [0]
                  },
                  height: '500px',
                  width: CHART_WIDTH,
                  initialShow: 10
                }}
                source={distributionValue}
              />
          }
        </div>
      </AdvanceSearchModal>
    </div>
  )
})

export default OrderOfStudents;
